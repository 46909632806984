import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Navbar from "./Navbar";
import Footer from "./Footer";
import Web from './Web';
import Graphic from './Graphic';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    fontSize: '22px',
    margin: 'auto',
    textDecoration:'none',
    [theme.breakpoints.down("sm")]: {
      fontSize: '14px'
    }
  }
}));

export default function ScrollableTabsButtonAuto() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Navbar />
      <AppBar position="static" color="default" style={{
        backgroundColor: 'lightblue', 
      }}>
        < Tabs
          value={value} style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"

        >
          <Tab label="Web Development" {...a11yProps(1)} className={classes.tab} />
          <Tab label="Graphic Desgin" {...a11yProps(0)} className={classes.tab} />
        </Tabs>
      </AppBar >
      <TabPanel value={value} index={0}>
        <Web />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Graphic />
      </TabPanel>
      <Footer />
    </div >
  );
}
